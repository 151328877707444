<template>
  <base-layout>
    <ChatNavigation></ChatNavigation>
    <ion-content>
      <div class="list-group col">
        <router-link :to="'/app/chats/' + chat.id" class="list-group-item list-group-item-action " aria-current="true" v-for="chat in rooms"
           :key="chat.id">
          <div class="d-flex flex-row">
            <div style="text-align: center; min-width: 50px;" class="col-1">
              <img v-if="chat.avatar_id"
                  :src="'https://fileserver.documedias.systems/file/' + chat.avatar_id + '/face/130/130'"
                  style="width:50px; height:50px; border-radius:50%">
              <img v-if="!chat.avatar_id"
                  src="@/assets/icon/avatar.png"
                  style="width:50px; height:50px; border-radius:50%">
            </div>
            <div  class="col-9 ">
              <div style="padding-left:15px;" class="d-flex flex-column w-100 justify-content-between">
                <h5 class="mt-2 mb-0">{{ chat.name }}</h5>
                <span  style="display:block; max-width:90vw;">{{ chat.last_message }}</span><!--:class="{'fw-bolder' : chat.unread > 0}"-->
              </div>
            </div>
              <div  class="col-1 d-flex flex-column align-items-center">
                <small>{{ moment(chat.updated).locale('de').format('dddd HH:mm') }}</small>
              </div>
               <span style="position: absolute;" class="badge bg-primary rounded-pill" v-if="chat.unread > 0">{{ chat.unread }}</span>
          </div>
        </router-link>
      </div>
    </ion-content>

  </base-layout>

</template>

<script>
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import ChatNavigation from "@/components/chat/ChatNavigation";
import moment from "moment"


export default defineComponent({
  name: "ChatList",
  components: {ChatNavigation},
  data() {
    return {
      /*chats: [
        {name: 'Volker Bach', id: 1, last_message: 'dolor sit amet', avatar_id: null},
        {
          name: 'Marco Millbreak',
          id: 2,
          last_message: 'dolor sit amet',
          avatar_id: 'd8695e1d-92e5-4ccd-8e17-4cf6042dc1af'
        },
        {name: 'Der Docta', id: 3, last_message: 'dolor sit amet', avatar_id: null},
        {name: 'Marc Molina', id: 4, last_message: 'dolor sit amet', avatar_id: null},
        {name: 'Antoni Verdugo', id: 5, last_message: 'dolor sit amet', avatar_id: null},
        {name: 'Monsieur Guiseque', id: 77, last_message: 'dolor sit amet', avatar_id: null},
        {name: 'Schars Linkel', id: 7, last_message: 'dolor sit amet', avatar_id: null}
      ],*/
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('chat', ['userlist', 'chatUser', 'currentRoom', 'roomlist', 'unreadTotal']),

    rooms: function () {
      let roomsToReturn = [];
      this.roomlist.forEach(room => {
        let findOpponent;
        let findMe;
        if (room.user.length == 2) {
          findOpponent = room.user.find(u => u.id != this.user.id);
          findMe = room.user.find(u => u.id == this.user.id);
        }
        let roomToAdd = {
          id: room.id,
          updated: room.updated_at,
          last_message: room.last_message ? room.last_message.text : '',
          name: room.user.length != 2 ? room.name : (findOpponent.firstname + ' ' + findOpponent.lastname),
          avatar_id: findOpponent ? findOpponent.avatar_id : null,
          unread: findMe ? findMe.unreadMessages : 0
        }
        roomsToReturn.push(roomToAdd);
      })
      roomsToReturn = roomsToReturn.sort((a,b)=>{
        return new Date(b.updated) - new Date(a.updated);
      });
      return roomsToReturn
    }
  },
  methods: {
    ...mapActions('chat', ['getUserList', 'registerUser', 'enterRoom', 'getRoomList', 'getUnreadMessagesTotalCount']),
    ...mapMutations('chat', ['setCurrentRoom']),
    async startChat(chat) {
      console.log(chat)
      this.$router.push('/app/chats/' + chat.id)
    }
  },
  async created() {
    this.moment = moment;
    await this.getRoomList();

    console.log(this.roomlist)
  },

  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {
        if (to.name == 'My Chats') {
          console.log('mychatsentered')

          this.moment = moment;
          await this.getRoomList();

          console.log('mychatsentered',this.roomlist)
        }
      }
    }
  }

});
</script>
<style lang="scss">


</style>