<template>
  <IonSegment :value="activeTab" mode="md">
    <IonSegmentButton
        value="participants"
        @click="selectTab('participants')"
    >
      <IonLabel>   {{ wordings[currentLanguage].CHAT_PARTICIPANTS }}</IonLabel>
    </IonSegmentButton>
    <IonSegmentButton
        value="chats"
        @click="selectTab('chats')"
    >
      <IonLabel>   {{ wordings[currentLanguage].CHAT_MY_CHATS }}<span v-if="unreadTotal > 0"
          class="position-absolute top-0 m-3 end translate-middle badge rounded-pill bg-primary">
                {{ unreadTotal }}
              </span></IonLabel>
    </IonSegmentButton>
  </IonSegment>

</template>

<script>
import {
} from "@ionic/vue";
import {
  IonSegment,
  IonSegmentButton,
  IonLabel
} from "@ionic/vue";
import {mapMutations, mapState} from "vuex";

export default {
  components: {
    IonSegment,
    IonSegmentButton,
    IonLabel,
  },
  data() {
    return {
      activeTab: 'participants'
    }
  },
  computed: {
    ...mapState('chat', ['currentChat','unreadTotal']),
        ...mapState('wordings', ['wordings', 'currentLanguage']),

  },
  methods: {
    ...mapMutations('chat', ['setCurrentRoom']),
    goBack() {
      this.setCurrentRoom(null);
    },
    selectTab(area) {
      this.activeTab = area;
      this.setCurrentRoom(null);
      if(area=='participants'){
        this.$router.push('/app/chat');
      }
      if(area=='chats'){
        this.$router.push('/app/mychats');
      }
    }
  },
  async created() {
  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {
        if (to.name == 'My Chats') {
          this.activeTab = 'chats';
        }
        if (to.name == 'Chat') {
          this.activeTab = 'participants';
        }
      }
    }
  }
};
</script>
<style lang="scss">



</style>